<template>
  <v-card class="py-4 px-6">
    <v-card-title>{{ $t('partnerView.dealRegistration') }}</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="submitForm()"
      >
        <v-row class="align-center">
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="formData.accountName"
              :label="$t('partnerView.accountName')"
              :rules="rules.accountName"
              data-cy="input-accountName"
              validate-on-blur
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="formData.email"
              :label="$t('partnerView.email')"
              :rules="rules.email"
              data-cy="input-email"
              validate-on-blur
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="formData.country"
              :label="$t('partnerView.country')"
              :items="countryCodes || []"
              item-text="value"
              item-value="id"
              :rules="rules.country"
              data-cy="input-country"
              :loading="countryCodesLoading"
              validate-on-blur
            >
              <template #item="{ item }">
                <span :data-cy="`state-item-${item.value}`">{{ item.value }}</span>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-btn
              rounded
              color="success"
              data-cy="submit-button"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('submit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <core-element-errors-message
        :show-errors="showApiErrors"
        :errors="errors"
      />
      <core-element-success-message
        v-if="dealRegistrationSubmitted"
        :message="$t('partnerView.dealRegistrationSubmitted')"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { validateEmail } from '@/utils/validations'

export default {
  name: 'DealRegistration',
  data () {
    return {
      formData: {
        accountName: '',
        email: '',
        country: ''
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.support.errors,
      loading: state => state.support.loading,
      showApiErrors: state => state.support.showApiErrors,
      dealRegistrationSubmitted: state => state.support.dealRegistrationSubmitted,
      countryCodes: state => state.countryCodes.countryCodes,
      countryCodesLoading: state => state.countryCodes.loading
    }),
    rules () {
      return {
        accountName: [v => !!v || this.$i18n.t('errors.fieldIsRequired')],
        email: [
          v => !!v || this.$i18n.t('errors.fieldIsRequired'),
          v => validateEmail(v) || this.$i18n.t('errors.wrongEmailFormat')
        ],
        country: [v => !!v || this.$i18n.t('errors.fieldIsRequired')]
      }
    }
  },
  async created () {
    await this.getCountryCodesIfNeed()
  },
  methods: {
    ...mapMutations('support', ['setErrors']),
    ...mapActions('countryCodes', ['getCountryCodesIfNeed']),
    ...mapActions('support', ['sendDealRegistration']),
    submitForm () {
      if (this.$refs.form.validate()) {
        this.setErrors({})
        this.sendDealRegistration(this.formData)
      }
    }
  }
}
</script>
